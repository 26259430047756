import React, { lazy, Suspense } from "react";
import { LazyLoader } from "../../../assets/loaders";
const LazyComponent = lazy(() => import("./PendingCases"));

export default function PendingCases() {
  return (
    <Suspense fallback={<LazyLoader />}>
      <LazyComponent />
    </Suspense>
  );
}
