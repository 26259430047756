import React, { useState, useEffect, useCallback } from "react";
import "./Operations.scss";
import axios from "axios";
import MainServices from "../../services/MainServices";
import {
  ButtonLoader,
  CustomerPortalLoader,
  LazyLoader,
} from "../../assets/loaders";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FaUser } from "react-icons/fa";
import "react-tabs/style/react-tabs.css";
import { RiTeamFill } from "react-icons/ri";
import { IoSearchSharp } from "react-icons/io5";
import { FaEnvelope, FaTruck } from "react-icons/fa";
import { deBounce } from "../../assets/functions/TimingFuntions";

import {
  FaBullhorn,
  FaCalendarAlt,
  FaFileAlt,
  FaCar,
  FaQuestionCircle,
} from "react-icons/fa";
import useAuth from "../../hooks/useAuth";
import { useNotifications } from "../../context/Notification";
import ServiceCompleted from "./ServiceCompleted";
import PendingCases from "./PendingCases";
import InProgressCases from "./InProgressCases";
import CompletedCases from "./CompletedCases";
import CancelCases from "./CancelCases";

const Dashboard = () => {
  const [pendingCasesLength, setPendingCasesLength] = useState(0);
  const [serviceCompletedLength, setServiceCompletedLength] = useState(0);
  const [inProgressCasesLength, setInProgressCasesLength] = useState(0);
  const [completedCasesLength, setCompletedCasesLength] = useState(0);
  const [cancelCasesLength, setCancelCasesLength] = useState(0)

  const [serivceCompleted, setSerivceCompleted] = useState([]);
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [allCases, setAllCases] = useState([]);
  const [searchString, setSearchString] = useState();

  const { refreshCases } = useNotifications();

  const { getAllCaseHistory, getCaseByReference } = MainServices();
  const navigate = useNavigate();

  const { admin } = useAuth();

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
      timeZone: "UTC",
    };
    const date = new Date(dateString);
    return date.toLocaleString("en-US", options);
  };

  //get all cases api
  const getAllCases = async () => {
    setDashboardLoading(true);
    const details = {
      stateKey: 6,
    };
    await axios
      .all([getAllCaseHistory(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            resData = resData.data ? resData.data : [];

            setAllCases(resData);
            setDashboardLoading(false);
          }
        }
        setDashboardLoading(false);
      })
      .catch(function (res) {
        console.log(res);
        setDashboardLoading(false);
        if (res.message) console.log("An error occurred in retreving Services");
      });
  };

  const getServiceCompleted = async () => {
    setDashboardLoading(true);
    const details = {
      stateKey: 5,
    };
    await axios
      .all([getAllCaseHistory(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            setServiceCompletedLength(resData.length);
            setSerivceCompleted(resData);
            setDashboardLoading(false);
          }
        }
        setDashboardLoading(false);
      })
      .catch(function (res) {
        console.log(res);
        setDashboardLoading(false);
        if (res.message) console.log("An error occurred in retreving Services");
      });
  };
  const getPendingCasesList = async () => {
    setDashboardLoading(true);
    const details = {
      stateKey: 1,
    };
    await axios
      .all([getAllCaseHistory(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            setPendingCasesLength(resData.length);

            setDashboardLoading(false);
          }
        }
        setDashboardLoading(false);
      })
      .catch(function (res) {
        console.log(res);
        setDashboardLoading(false);
        if (res.message) console.log("An error occurred in retreving Services");
      });
  };

  const getInProgressCasesList = async () => {
    setDashboardLoading(true);
    const details = {
      stateKey: 2,
    };
    await axios
      .all([getAllCaseHistory(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            resData = resData.data ? resData.data : [];

            setInProgressCasesLength(resData.length);
            setDashboardLoading(false);
          }
        }
        setDashboardLoading(false);
      })
      .catch(function (res) {
        console.log(res);
        setDashboardLoading(false);
        if (res.message) console.log("An error occurred in retreving Services");
      });
  };

  const getCompeletedCasesList = async () => {
    setDashboardLoading(true);
    const details = {
      stateKey: 3,
    };
    await axios
      .all([getAllCaseHistory(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            resData = resData.data ? resData.data : [];

            setCompletedCasesLength(resData.length);
            setDashboardLoading(false);
          }
        }
        setDashboardLoading(false);
      })
      .catch(function (res) {
        console.log(res);
        setDashboardLoading(false);
        if (res.message) console.log("An error occurred in retreving Services");
      });
  };

  const getCancelCasesList = async () => {
    setDashboardLoading(true);
    const details = {
      stateKey: 4,
    };
    await axios
      .all([getAllCaseHistory(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            resData = resData.data ? resData.data : [];

            setCancelCasesLength(resData.length);
            setDashboardLoading(false);
          }
        }
        setDashboardLoading(false);
      })
      .catch(function (res) {
        console.log(res);
        setDashboardLoading(false);
        if (res.message) console.log("An error occurred in retreving Services");
      });
  };

  const searchByReference = async (referenceId) => {
    if (!referenceId) {
      getAllCases();
      getPendingCasesList();
      getServiceCompleted();
      getCompeletedCasesList();
      getCancelCasesList();
      return;
    }

    setSearchLoading(true);

    const details = {
      caseReference: referenceId,
    };
    try {
      const res = await getCaseByReference(details);
      if (res && res.success) {
        setAllCases([res.data]);
        setSearchLoading(false);
      }
    } catch (error) {
      console.log("An error occurred in retrieving services", error);
    } finally {
      setSearchLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    deBounce((value) => {
      setSearchString(value);
      searchByReference(value);
    }, 1000),
    []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  useEffect(() => {
    getAllCases();
    getServiceCompleted();
    getPendingCasesList();
    getInProgressCasesList();
    getCompeletedCasesList();
    getCancelCasesList();
  }, [refreshCases]);

  return (
    <div className="main-content-container">
      <div className="sidecontent">
        <Tabs>
          <TabList>
            <Tab>
              <span
                style={{
                  color: "#d52429",
                  fontWeight: "600",
                  paddingRight: "2px",
                  fontSize: "11px",
                }}
              >
                {pendingCasesLength}
              </span>
              Pending
            </Tab>
            <Tab>
              <span
                style={{
                  color: "#d52429",
                  fontWeight: "600",
                  paddingRight: "2px",
                  fontSize: "11px",
                }}
              >
                {inProgressCasesLength}
              </span>
              In Progress
            </Tab>
            <Tab>
              <span
                style={{
                  color: "#d52429",
                  fontWeight: "600",
                  paddingRight: "2px",
                  fontSize: "11px",
                }}
              >
                {completedCasesLength}
              </span>
              Completed
            </Tab>
            <Tab>
              <span
                style={{
                  color: "#d52429",
                  fontWeight: "600",
                  paddingRight: "2px",
                  fontSize: "11px",
                }}
              >
                {cancelCasesLength}
              </span>
              Cancel
            </Tab>
            <Tab>
              <span
                style={{
                  color: "#d52429",
                  fontWeight: "600",
                  paddingRight: "2px",
                  fontSize: "11px",
                }}
              >
                {serviceCompletedLength}
              </span>
              Service Completed
            </Tab>
          </TabList>

          <TabPanel>
            <PendingCases setPendingCasesLength={setPendingCasesLength} />
          </TabPanel>
          <TabPanel>
            <InProgressCases
              setInProgressCasesLength={setInProgressCasesLength}
            />
          </TabPanel>

          <TabPanel>
            <CompletedCases setCompletedCasesLength={setCompletedCasesLength} />
          </TabPanel>

          <TabPanel>
            <CancelCases />
          </TabPanel>

          <TabPanel>
            <ServiceCompleted
              setServiceCompletedLength={setServiceCompletedLength}
            />
          </TabPanel>
        </Tabs>
      </div>
      <div className="main-dashboard">
        <>
          <div className="count-container">
            <Tabs>
              <TabList>
                <Tab>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".34rem",
                    }}
                  >
                    {" "}
                    <FaUser /> My Open Cases
                  </span>
                </Tab>
                <Tab>
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".34rem",
                    }}
                  >
                    <RiTeamFill /> My Teams Open Cases
                  </span>
                </Tab>
                <Tab>
                  {" "}
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".34rem",
                    }}
                  >
                    {" "}
                    <IoSearchSharp />
                    Search
                  </span>
                </Tab>
              </TabList>

              <TabPanel>
                <div className="case-info-box">
                  <div className="left-section">
                    <div className="header">
                      <FaEnvelope className="icon" />
                      <div className="details">
                        <a href="#" className="code-link">
                          C002VZC -{" "}
                          <span style={{ color: "#000", fontSize: "14px" }}>
                            Mohammed Muzaffar Khan
                          </span>
                        </a>
                        <span className="date-time">2024/02/27 07:59</span>
                      </div>
                    </div>
                    <div className="info">
                      <div className="info-row">
                        <FaFileAlt className="value-icon" />
                        <span className="label">Opened By</span>
                        <span className="value">{admin.firstName}</span>
                      </div>
                      <div className="info-row">
                        <FaCar className="value-icon" />
                        <span className="label">Vehicle</span>
                        <span className="value">AP21D7299</span>
                      </div>
                      <div className="info-row">
                        <FaFileAlt className="value-icon" />
                        <span className="label">Policy</span>
                        <span className="value"> </span>
                      </div>
                      <div className="info-row">
                        <FaQuestionCircle className="value-icon" />
                        <a href="#" className="operations-link">
                          Operations
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="right-section">
                    <span className="time-ago">2 months ago</span>
                    <div className="service-info">
                      <div className="service-header">
                        <FaTruck className="icon" />
                        <span className="service-name">Accident Tow</span>
                        <span className="service-date">2024/05/28 18:46</span>
                        <span className="service-status">
                          Awaiting Manual Assignment
                        </span>
                      </div>
                      <div className="progress">
                        <span className="step active">Dispatch</span>
                        <span className="step">Arrive </span>
                        <span className="step">Complete </span>
                        <span className="step">Confirm </span>
                        <span className="step">Finalize</span>
                        <span className="step">Finance</span>
                        <span className="step">Verify</span>
                        <span className="step">Invoice</span>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <h2>content</h2>
              </TabPanel>
              <TabPanel>
                <h2>content</h2>
              </TabPanel>
            </Tabs>
          </div>
        </>
      </div>
    </div>
  );
};

export default Dashboard;
