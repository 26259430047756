import React, { lazy, Suspense } from "react";
import { LazyLoader } from "../../../assets/loaders";
const LazyComponent = lazy(() => import("./ServiceCompleted"));

export default function ServiceCompleted() {
  return (
    <Suspense fallback={<LazyLoader />}>
      <LazyComponent />
    </Suspense>
  );
}
